<template>
  <div
    class="w-full"
    style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%);"
  >
    <div class="flex items-center justify-between">
      <a href="/" class="-1/3 md:w-1/4">
        <img
          src="../assets/images/color-logo.png"
          class="w-16 h-16 my-5 ml-5 rounded-full sm:w-24 sm:h-24"
        />
      </a>
      <div class="justify-end lg:flex">
      <h3
        v-if="!isMobile()"
        class="w-1/4 text-2xl font-bold text-white md:w-1/3 sm:text-3xl md:text-5xl"
      >
        <router-link to="/">BNFS</router-link>
      </h3>
      </div>
      <ul
        class="flex justify-end w-2/3 text-xs font-bold text-white lg:w-1/2 xl:w-1/3 sm:text-lg"
      >
        <li @mouseover="hover()" @mouseleave="unhover()">
          <router-link to="/formations" class="relative"
            >Les formations</router-link
          >
          <ul
            v-if="display && !isTab()"
            class="absolute z-10 text-left"
            style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%);"
          >
            <li
              v-for="formation in formations"
              :key="formation.id"
              class="py-2 ml-2"
            >
              <router-link :to="{ path: '/formations/' + formation.id }">{{
                formation.title
              }}</router-link>
            </li>
          </ul>
        </li>
        <li class="ml-4 md:ml-8 lg:ml-10">
          <router-link to="/about">A propos</router-link>
        </li>
        <li class="mx-4 md:mx-8 lg:mx-10">
          <router-link to="/contacts">Contacts</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from "@/assets/formationsStore";

export default {
  data() {
    return {
      formations: store.state.formations,
      display: false,
    };
  },

  methods: {
    hover() {
      this.display = true;
    },
    unhover() {
      this.display = false;
    },
      isTab() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "IronMaiden";
  src: url(../assets/fonts/IronMaiden400.ttf);
}
h3 {
  font-family: "IronMaiden";
}
</style>

// ajouter liste deroulante sur formation dans l'entete // plus d'info vers un
formulaire de contact // page d'acceuil refer to chram.be slider page d'acceuil
-> bottom cards (particulier, ecole, entreprise) + exemple header
