<template>
  <div
    class="w-full h-full min-h-screen py-16"
    style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%);"
  >
    <div class="sm:mx-16">
      <div class="single-formation">
        <h3
          class="w-full mb-4 font-sans text-xl font-extrabold underline sm:text-4xl"
        >
          {{ formation.title }}
        </h3>
        <div class="sm:text-lg">
          <p class="mb-4 text-xl font-extrabold">
            Durée: {{ formation.duration }}
          </p>
          <p class="mx-10 mb-2 text-justify">{{ formation.longDescription }}</p>
          <div class="flex justify-between mt-10">
            <div>
              <div class="mx-10 mb-4 text-left">
                <p class="my-2 font-bold">{{ formation.intro }}</p>
                <ul>
                  <li
                    class="list-disc"
                    v-for="item in formation.steps"
                    :key="item.id"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div
                v-if="formation.intro2 || formation.steps2"
                class="mx-10 mb-4 text-left"
              >
                <p v-if="formation.intro2" class="my-2 font-bold">
                  {{ formation.intro2 }}
                </p>
                <ul v-if="formation.steps2">
                  <li
                    class="list-disc"
                    v-for="item in formation.steps2"
                    :key="item.id"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div
                v-if="formation.intro3 || formation.steps3"
                class="mx-10 mb-4 text-left"
              >
                <p v-if="formation.intro3" class="my-2 font-bold">
                  {{ formation.intro3 }}
                </p>
                <ul v-if="formation.steps3">
                  <li
                    class="list-disc"
                    v-for="item in formation.steps3"
                    :key="item.id"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div
                v-if="formation.intro4 || formation.steps4"
                class="mx-10 mb-4 text-left"
              >
                <p v-if="formation.intro4" class="my-2 font-bold">
                  {{ formation.intro4 }}
                </p>
                <ul v-if="formation.steps4">
                  <li
                    class="list-disc"
                    v-for="item in formation.steps4"
                    :key="item.id"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <transition name="fade" appear mode="out-in">
              <img
                v-if="imageSwitch"
                :src="formation.imagePath[i]"
                alt=""
                class="h-56 mr-5 rounded-lg w-96 md:h-96"
                key="switchoff"
              />
              <img
                v-else
                :src="formation.imagePath[i]"
                alt=""
                class="h-56 mr-5 rounded-lg w-96 md:h-96"
                key="switchon"
              />
              b
            </transition>
          </div>
        </div>
        <div class="flex items-start justify-between">
          <router-link class="w-full font-bold" to="/contacts"
            >Plus d'infos ?</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/assets/formationsStore";
export default {
  name:"Single",
  store: store,
  data() {
    return {
      formation: store.state.formations[this.$route.params.id - 1],
      i: 0,
      imageSwitch: true,
    };
  },
  methods: {
    carouselImage() {
      setInterval(() => {
        this.imageSwitch = !this.imageSwitch;
        this.i === this.formation.imagePath.length - 1
          ? (this.i = 0)
          : this.i++;
      }, 15000);
    },
    track() {
      this.$gtag.screenview({ app_name: "Single", screen_name: "Single-"+this.formation.title });
    },
  },
  mounted() {
    this.carouselImage();
  },
};
</script>

<style>
@keyframes carouselImage {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
.fade-enter-active {
  animation: carouselImage 3s linear;
}
.fade-leave-active {
  animation: carouselImage 3s linear reverse;
}
</style>
