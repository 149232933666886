import Vue from "vue";
import Vuex from "vuex";
import image1 from "../assets/images/reanimation.jpeg";
import image2 from "../assets/images/01.jpg";
import image3 from "../assets/images/02.jpg";
import image4 from "../assets/images/03.jpg";
import image5 from "../assets/images/04.jpg";
import image6 from "../assets/images/05.jpg";
import image7 from "../assets/images/06.jpg";
import image8 from "../assets/images/rea2.jpeg";
import image9 from "../assets/images/rea-curse.jpg";
import image10 from "../assets/images/extincteurs.jpg";
import image11 from "../assets/images/bandage.jpg";
import image12 from "../assets/images/bébé.jpg";
import image13 from "../assets/images/bébé2.jpg";
import image14 from "../assets/images/dea.jpg";
import image15 from "../assets/images/dea2.jpg";
import image16 from "../assets/images/defibrillateur.jpg";
import image17 from "../assets/images/entreprise.jpg";
import image18 from "../assets/images/entreprise2.jpg";
import image19 from "../assets/images/entreprise3.jpeg";
import image20 from "../assets/images/incendie.jpg";
import image21 from "../assets/images/incendie2.jpg";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    formations: [
      {
        id: 1,
        title: "Brevet de premier secours",
        duration: "15 heures",
        shortDescription:
          "Tout au long de la formation, vous reproduirez le schéma à suivre au cours de tout type d’intervention afin d’administrer les premiers soins efficacement et en toute sécurité",
        longDescription:
          "Tout au long de la formation, vous reproduirez le schéma à suivre au cours de tout type d’intervention afin d’administrer les premiers soins efficacement et en toute sécurité, ainsi que la manière de communiquer les informations pertinentes aux services de secours (112).",
        intro: "Au cours de la formation, vous:",
        steps: [
          "Vous entrainerez à la réanimation et la défibrillation de l’adulte, la désobstruction, la position latérale de sécurité, la stabilisation de la tête en cas de suspicion de traumatisme crânien, cervical ou dorsal",
          "Apprendrez les conduites à tenir en cas de malaise cardiaque, accident vasculaire cérébral, hémorragie, membre sectionné, plaie, brulure, fracture, intoxication, etc.",
          "L’âge minimum pour y participer est de 12 ans ; entre 12 et 15 ans, il est impératif d’être accompagné d’un majeur",
          "La participation à la totalité de la formation est requise (notamment pour l’obtention du brevet)",
          "Il n’y a pas d’examen, cette formation s’adresse à tout le monde.",
          "Il n’y a pas d’examen",
        ],
        imagePath: [image9, image1, image11],
      },
      {
        id: 2,
        title: "Initiation à la réanimation pédiatrique",
        duration: "3 heures",
        shortDescription:
          "Découvrez dans le détail les premiers gestes qui sauvent face à un enfant qui présente un problème de santé (inconscience, plaie, commotion, obstruction, détresse respiratoire…)",
        longDescription:
          "Sans pour autant avoir suivi une formation en secourisme, les parents, les enseignants, les puéricultrices doivent être à même de réagir efficacement dans certaines situations critiques dont un bébé/enfant pourrait être victime. Pour ce faire, il existe des gestes simples mais qui sont d’une importance capitale puisqu’ils permettent d’augmenter sensiblement les chances de sauver une vie. Or, ces gestes-là, on ne peut pas les improviser, il faut les connaître, y être formé et être capable de les mettre en pratique quand survient le problème. Au terme de la séance, les participants auront appris les techniques de réanimation du bébé et de l’enfant, les techniques de désobstruction, les gestes qui sauvent, c’est-à-dire les gestes à faire, mais aussi et surtout, les gestes à ne pas faire ! Cette formation s’adresse donc à tous les parents, enseignants, puéricultrices qui ne peuvent pas bénéficier immédiatement de l’aide du corps médical mais qui souhaitent pouvoir réagir adéquatement dans l’attente des secours.",
        intro:
          "Vous vous entrainerez individuellement sur les techniques suivantes : ",
        steps: [
          "La réanimation du bébé ( 1 an)",
          "La désobstruction du bébé",
          "La désobstruction de l’enfant",
          "L’âge minimum pour y participer est de 15 ans",
          "Il n’y a pas d’examen",
          "Cette formation s’adresse principalement aux personnes côtoyant des enfants et des bébés.",
        ],
        imagePath: [image4, image12, image13],
      },
      {
        id: 3,
        title: "Brevet de secouriste en entreprise",
        duration: "16 heures",
        shortDescription:
          "Les entreprises ont souvent l’obligation de faire suivre à certains membres du personnel une formation délivrant un brevet de secouriste reconnu",
        longDescription:
          "Les entreprises ont souvent l’obligation de faire suivre à certains membres du personnel une formation délivrant un brevet de secouriste reconnu. Si vous n’êtes pas soumis à cette obligation, des compétences en secourisme peuvent néanmoins s’avérer utiles. L’organisation d’une bonne formation de base, de cours de recyclage ou d’une formation d’initiation incombe aux conseillers en prévention. En tant que formateur reconnu, Bnfs délivre des formations aux premiers secours sur mesure et certifiées.",
        intro:
          "Vous vous entrainerez individuellement sur les techniques suivantes : ",
        steps: [
          "a réanimation & défibrillation de l'adulte",
          "la réanimation du bébé",
          "la mise en position latérale de sécurité de l’adulte et de l’enfant",
          "la désobstruction de l’adulte et de l’enfant et du bébé",
        ],
        intro2:
          "A travers des exposés interactifs, ainsi que des mises en situation, vous approfondirez la physiologie et les pathologies :",
        steps2: [
          "du système nerveux",
          "de l’appareil respiratoire",
          "de l’appareil circulatoire",
          "de la peau",
          "de l’appareil locomoteur",
        ],
        intro3: "Seront également abordés :",
        steps3: [
          "le cadre légal et la déontologie autour des actions du secouriste",
          "le contenu et l’utilisation de la boite de secours",
        ],
        intro4:
          "A travers des exposés interactifs, ainsi que des mises en situation, vous approfondirez la physiologie et les pathologies :",
        steps4: [
          "L’âge minimum pour y participer est de 15 ans",
          "Les inscriptions sont obligatoire",
        ],
        imagePath: [image5, image17, image18],
      },
      {
        id: 4,
        title: "Formation de base du secouriste en milieu professionnel",
        duration: "3 jours - 18 heures",
        shortDescription:
          "La formation de base de secouriste permet au travailleur d’acquérir les connaissances et aptitudes de bases exigées pour reconnaître des états de santé qui menacent la vie des personnes, de pouvoir appliquer les principes des premiers secours appropriés à une victime d’un accident ou d’un malaise sur le lieu du travail",
        longDescription:
          "La formation de base de secouriste permet au travailleur d’acquérir les connaissances et aptitudes de bases exigées pour reconnaître des états de santé qui menacent la vie des personnes, de pouvoir appliquer les principes des premiers secours appropriés à une victime d’un accident ou d’un malaise sur le lieu du travail, dans l’attente de l’intervention des services spécialisés dans l’assistance médicale urgente. Cette formation de base doit être complétée par une formation supplémentaire liée aux activités de l’entreprise qui présentent des risques spécifiques pour lesquels les connaissances et aptitudes de base sont insuffisantes. La formation de base de secouriste est organisée conformément au titre 5 « Premiers Secours » du livre 1er du code du bien-être au travail, du 28 avril 2017. Cette formation permet d’atteindre les objectifs définis dans le cadre légal :",
        intro:
          "Notre formation se base sur la pédagogie active, des mises en situation avec grimages, des exercices pratiques, des exercices réflexifs, des échanges/discussions. Les thématiques suivantes seront abordées pendant la formation:",
        steps: [
          "Le cadre légal et le rôle du secouriste en entreprise",
          "Les règles essentielles d'intervention dont l'appel des secours professionnels",
          "Le contrôle des fonctions vitales",
          "La réanimation cardiopulmonaire de l'adulte avec défibrillateur (DEA)",
          "La position latérale de sécurité (PLS) et le retournement",
          "L’étouffement & la désobstruction",
          "Les hémorragies, plaies, amputations, brulures",
          "Les traumatismes de l'appareil locomoteur, crâniens et vertébraux",
          "Les malaises (malaise cardiaque, Accident Vasculaire Cérébral, convulsions, détresse respiratoire ...)",
          "Les intoxications",
          "Le contenu de la trousse de secours",
          "Les techniques d'emballages et méthodes de soutien",
          "Formation Intra-entreprise (dans vos locaux)",
        ],
        intro2:
          "Validité : La durée de validité de la certification de secouriste est en lien avec le recyclage annuel (ou bisannuel sur base d’une analyse de risque) de minimum 4 heures. Cette certification est octroyée à titre personnel , agréée par le SPF Emploi, Travail & Concertation Sociale. Conditions d’accès : Une participation active durant la totalité de la formation, une bonne compréhension du français et une aptitude physique à pratiquer les gestes techniques en lien avec les premiers secours sont nécessaires pour l’obtention de la certification.",
        intro3:
          "Nous sommes agréé par le SPF Emploi, Travail & Concertation Sociale pour dispenser la formation de base de secouriste (travailleur qui dispense les premiers secours sur le lieu de travail). Dans les entreprises classées dans le groupe A, B ou C, l’employeur prévoit un nombre suffisant de personnel infirmier, de secouristes ou d’autres personnes désignées en fonction du nombre de travailleurs, des caractéristiques des activités, et des résultats de l’analyse des risques, de manière à ce que les premiers secours puissent être dispensés pendant toute la durée du travail.",

        imagePath: [image19, image7, image5],
      },
      {
        id: 5,
        title: "Recyclage du secouriste en milieu professionnel",
        duration: "4 heures à 6 heures",
        shortDescription:
          "Le recyclage permet au secouriste de maintenir les connaissances et aptitudes de base et l’enseignement de pratiques ou de connaissances nouvelles ou évoluées en matière de premiers secours",
        longDescription:
          "Le recyclage permet au secouriste de maintenir les connaissances et aptitudes de base et l’enseignement de pratiques ou de connaissances nouvelles ou évoluées en matière de premiers secours. Le secouriste est le premier acteur dans la chaîne des secours sur le lieu de travail. Le recyclage est annuel excepté si l’employeur peut démontrer que le recyclage annuel n’est pas nécessaire, sur base d’une analyse des risques préalable et sur l’avis du conseiller en prévention-médecin du travail et du comité. Dans ce cas, le recyclage peut être suivi tous les deux ans. Un secouriste qui ne peut assister à une séance de recyclage, doit assister à une autre séance de recyclage dans les douze mois qui suivent le recyclage initialement prévu. En cas d’impossibilité, le travailleur n’est plus censé disposer des connaissances et aptitudes de base en matière de premiers secours et devra alors suivre à nouveau une formation complète afin de pouvoir intervenir en tant que secouriste au sein de l’entreprise.",
        intro:
          "Notre formation se base sur la pédagogie active, des mises en situation avec grimages, des exercices pratiques, des échanges/discussions. Chaque année, les thématiques et pratiques suivantes sont systématiquement révisées :",
        steps: [
          "Les règles essentielles d’intervention dont l'appel des secours professionnels",
          "Le contrôle des fonctions vitales",
          "La réanimation cardiopulmonaire de l'adulte avec défibrillateur (DEA)",
          "La position latérale de sécurité (PLS) et le retournement",
          "L’étouffement & la désobstruction",
        ],
        intro2:
          "+ un module de 2 heures comprenant des thématiques complémentaires en fonction des besoins et des souhaits de l’entreprise :",
        steps2: [
          "Module malaises : accident vasculaire cérébral, malaise cardiaque, difficulté respiratoire, état de faiblesse générale, convulsion",
          "Modules traumatismes locomoteurs : traumatismes crâniens, lésions de la colonne vertébrale, fracture, luxation, entorse",
          "Module plaies : plaies simples et graves, hémorragie, amputation",
          "Module brulure : brulure thermique et chimique, gelure, brulure à l’œil",
          "Module pédiatrie : réanimation, étouffement, malaises spécifiques.",
        ],
        intro3:
          "Conditions d'accès : avoir son certificat de secouriste en milieu professionnel ; être en ordre de recyclage (recyclage de 4 heures minimum annuellement ou tous les deux ans sur base de l’analyse de risque). Validité : la durée de validité de la Certification de Secouriste est en lien avec le recyclage annuel (ou bisannuel sur base d’une analyse de risque) de minimum 4 heures. Cette certification est octroyée à titre personnel.",
        intro4:
          "Type de formation: Formation Intra-entreprise (dans vos locaux)",
        imagePath: [image18, image19, image17],
      },
      {
        id: 6,
        title: "Initiation aux premiers secours",
        duration: "4 heures",
        shortDescription:
          "Cette formation permettra aux travailleurs de découvrir les notions de base en premiers secours afin de pouvoir intervenir face aux situations les plus courantes de malaise ou d’accident",
        longDescription:
          "Cette formation permettra aux travailleurs de découvrir les notions de base en premiers secours afin de pouvoir intervenir face aux situations les plus courantes de malaise ou d’accident. Ils apprendront à utiliser et adapter le contenu de la boîte de secours en fonction des besoins spécifiques liés à leur activité professionnelle.",
        intro:
          "Cette formation se base sur la pédagogie active, les mises en situation, les exercices pratiques, les échanges / discussions. Les thématiques suivantes seront abordées:",
        steps: [
          "Les règles essentielles d’intervention dont l’appel des secours",
          "Le contrôle des fonctions vitales",
          "La position latérale de sécurité (PLS)",
          "Les malaises : état de faiblesse générale, difficulté respiratoire",
          "Les convulsions",
          "La boîte de secours",
        ],
        intro2:
          "+ des thématiques complémentaires en fonction des besoins et des souhaits de l’entreprise:",
        steps2: [
          "Accident vasculaire cérébral, malaise cardiaque.",
          "Plaies, brulures, traumatismes et étouffement.",
        ],
        intro3: "En pratique",
        steps3: [
          "Type de formation : intra (dans vos locaux)",
          "Conditions d'accès : une participation active durant la totalité de la formation et une bonne compréhension du français sont nécessaires.",
        ],
        imagePath: [image6, image2, image3],
      },
      {
        id: 7,
        title: "Réagir face à l'urgence : situations exceptionelles",
        duration: "4 heures",
        shortDescription:
          "Apprenez les premiers réflexes en cas de situation d'urgence collective (explosion, catastrophe…)",
        longDescription:
          "Apprenez les premiers réflexes en cas de situation d'urgence collective (explosion, catastrophe…).  Au terme de cette formation, vous aurez acquis quelques réflexes qui vous permettront de vous mettre en sécurité ainsi que vos proches et d'alerter efficacement les secours. Vous connaîtrez également les premiers soins à réaliser en attendant l'arrivée des secours (hémorragies, brulures, inconscience...) Vous serez également capable de réaliser une réanimation avec ou sans défibrillateur (DEA).",
        intro:
          " La formation se base sur des exercices pratiques, des échanges / discussions. Les thématiques suivantes seront abordées:",
        steps: [
          "Situation exceptionnelle, réactions des victimes et des témoins",
          "Se mettre en sécurité et sécuriser les autres",
          "Appel des secours professionnels",
          "Premiers soins adaptés aux situations exceptionnelles: hémorragies, inconscience, brulures",
          "Impact psychosocial",
          "Réanimation (sans insufflations) et défibrillation",
          "Conditions d'accès : une participation active durant la totalité de la formation et une bonne compréhension du français sont nécessaires.",
        ],
        imagePath: [image2, image8, image3],
      },
      {
        id: 8,
        title:
          "Formation aux premiers secours aux enfants avec pratique de la réanimation cardio-pulmonaire",
        duration: "16 heures",
        shortDescription:
          "La formation se base sur la pédagogie active, des mises en situation, des exercices pratiques, des échanges/discussions",
        longDescription:
          "La formation se base sur la pédagogie active, des mises en situation, des exercices pratiques, des échanges/discussions.",
        intro: "Les thématiques suivantes sont abordées:",
        steps: [
          "Les règles essentielles d'intervention dont l'appel des secours professionnels",
          "La réanimation cardiopulmonaire de l’enfant",
          "La position latérale de sécurité (PLS)",
          "L’étouffement & la désobstruction",
          "Les hémorragies, plaies, brulures",
          "Les traumatismes de l'appareil locomoteur, crâniens et vertébraux",
          "Les malaises (fièvre, maux de tête ou de ventre, convulsions, détresse respiratoire ...)",
          "Les intoxications",
          "Saignement de nez et dent cassée",
        ],
        intro2: "En pratique",
        steps2: [
          "Type de formation : intra (dans vos locaux)",
          "Conditions d'accès : une participation active durant la totalité de la formation et une bonne compréhension du français sont nécessaires.",
        ],
        imagePath: [image12, image4, image13],
      },
      {
        id: 9,
        title: "Initiation à l'utilisation des défibrilateurs (DEA)",
        duration: "2 heures",
        shortDescription:
          "Cette formation est une initiation à la réanimation et à l’utilisation du défibrillateur (DEA)",
        longDescription:
          "Cette formation est une initiation à la réanimation et à l’utilisation du défibrillateur (DEA). Pour qui : Toute personne désirant s’initier à la réanimation cardio-pulmonaire ainsi qu’à l’utilisation du défibrillateur.	",
        intro:
          "Objectif: Être capable d’agir auprès d’une personne victime d’un problème cardiaque et oser utiliser le défibrillateur si nécessaire.",
        steps: [
          "Vous entrainerez à la réanimation et la défibrillation de l’adulte, la désobstruction, la position latérale de sécurité, la stabilisation de la tête en cas de suspicion de traumatisme crânien, cervical ou dorsal",
          "Apprendrez les conduites à tenir en cas de malaise cardiaque, accident vasculaire cérébral, hémorragie, membre sectionné, plaie, brulure, fracture, intoxication, etc.",
          "L’âge minimum pour y participer est de 12 ans ; entre 12 et 15 ans, il est impératif d’être accompagné d’un majeur",
        ],
        intro2: "En pratique:",
        steps2: [
          "Certification Attestation de participation délivrée en fin de formation",
          "Il est égallement possible de recevoir la Formation RCP/DEA au format de 4h",
          "Ce format de formation reprend la même base que la formation de 2 heures. Et comprend des mises en situation pratiques encadrées lors desquelles les participants pourront s’exercer à placer une personne en PLS, à réaliser un massage cardiaque seul ou avec un relais et à utiliser un défibrillateur.",
        ],
        imagePath: [image14, image15, image16],
      },
      {
        id: 10,
        title:
          "Equipier de Première Intervention (Service de Lutte Contre l’Incendie SLCI)",
        duration: "6 heures",
        shortDescription:
          "Optimaliser la sécurité en sensibilisant l’ensemble du personnel au risque feu",
        longDescription:
          "Personne n’est à l’abri d’un incendie! L’arrêté royal du 28 mars 2014 relatif à la prévention de l’incendie sur les lieux de travail exige que « l’employeur donne aux travailleurs la formation nécessaire relative aux mesures de prévention ». La formation met le participant face à des exercices d’évacuation ou en situation d’intervention tout en préservant sa sécurité.",
        intro: "Objectif:",
        steps: [
          "Prendre conscience de l’importance de la prévention incendie au quotidien",
          "Agir rapidement et efficacement en cas d’incendie",
          "Savoir reconnaître et manipuler correctement les différents moyens de première intervention et les moyens d’intervention spécifiques",
          "Être conscient des dangers liés au feu",
          "Percevoir les limites d’une première intervention",
          "Acquérir une connaissance théorique de base sur le feu",
          "Connaître les différentes situations amenant à l’évacuation",
          "Connaître les différentes techniques d’évacuation et savoir les implémenter en entreprise",
        ],
        intro2: "Théorie (3 heures)",
        steps2: [
          "Rôle et missions des équipiers de première intervention",
          "Statistiques et réalité de l’incendie en Belgique",
          "Règles et conduite à suivre en cas d’incendie",
          "Les dangers liés aux fumées",
          "Le triangle du feu",
          "Les classes de feu",
          "Les sources d’énergie calorifique causes d’incendie",
          "Mesures de prévention pour éviter la naissance, le développement et la propagation d’un incendie",
          "Agents extincteurs",
        ],
        intro3: "Pratique (3 heures)",
        steps3: [
          "Démonstration et exercice de mise sous contrôle d’un feu de friteuse avec participation individuelle",
          "Démonstration de mise en service et de fonctionnement des moyens de première intervention : CO2 – poudre – eau & additif – RIA (dévidoir)",
          "Exercice individuel d’extinction au moyen d’un extincteur au CO2",
          "Exercice individuel d’extinction au moyen d’un extincteur à poudre",
          "Exercice individuel d’extinction au moyen d’un extincteur à mousse",
          "Donner l’alerte / donner l’alarme",
        ],
        imagePath: [image10, image20, image21],
      },
    ],
  },
});

//titre
//durée
//cours résumé
//pour qui? ...
//Objectifs général
//Déroullement
