<template>
  <div
    class="pb-16"
    style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%);"
  >
    <div
      v-for="formation in formations"
      :key="formation.id"
      class="mb-5 sm:mb-0"
    >
      <div
        class="grid grid-cols-3 sm:py-8 sm:mx-16"
        v-bind:class="{
          reverse: formation.id % 2 === 0,
        }"
      >
        <div class="col-span-2 sm:p-12">
          <h6 class="mb-2 text-lg font-extrabold sm:mb-5 sm:text-3xl">
            {{ formation.title }}
          </h6>
          <p class="mb-2 text-xs sm:mb-5 sm:text-base">
            {{ formation.shortDescription }}
            <br />
          </p>
          <router-link
            :to="{ path: '/formations/' + formation.id }"
            class="text-xs sm:text-base"
            >Read More</router-link
          >
        </div>
        <div class="flex items-center justify-center">
          <img
            v-bind:src="formation.imagePath[0]"
            class="w-full h-40 rounded-md sm:h-80"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/assets/formationsStore";

export default {
  name:"Formations",
  data() {
    return {
      formations: store.state.formations,
    };
  },
  methods: {
    track() {
      this.$gtag.screenview({ app_name: "Formations", screen_name: "Formations" });
    },
  },
    mounted() {
  },
};
</script>

<style>
.reverse {
  direction: rtl;
}
</style>
