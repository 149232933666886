<template>
  <div
    class="h-full"
    style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%); "
  >
    <Splide :options="options" @splide:move="textAnimation">
      <Splide-slide v-for="slide in slides" :key="slide.img">
        <div
          class="h-screen font-black text-left text-white bg-center bg-no-repeat bg-cover sm:text-3xl -mb-36 :sm-mb-52"
          :style="{
            backgroundImage: `url(${slide.img})`,
          }"
        >
          <div
            class="absolute flex items-center justify-center w-3/4 w-full h-full leading-loose"
          >
            <div class="mx-10">
              <!-- <transition name="slide" appear mode="out-in"> -->
              <p
                class="p-10 rounded-lg"
                style="box-shadow: -24px 24px 20px 0px rgba(0,0,0,0.54);
                        background-color:rgba(48, 86, 163,0.7)"
              >
                {{ slide.text }}
              </p>
              <!-- </transition> -->
            </div>
          </div>
        </div>
      </Splide-slide>
    </Splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import image1 from "../assets/images/rea2.jpeg";
import image2 from "../assets/images/extincteurs.jpg";
import image3 from "../assets/images/07.jpg";
export default {
  name:'Home',
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      slideNumber: 0,
      options: {
        rewind: true,
        perPage: 1,
        autoplay: true,
        pauseOnHover: false,
        type: "loop",
        speed: "3000",
        easing: "ease-in-out",
        arrows: false,
        interval: 10000,
      },
      slides: [
        {
          id: 0,
          img: image1,
          text:
            "Nous avons la conviction que la connaissance des gestes de premiers secours est une compétence qui doit être maîtrisée par tous les citoyens.",
          textColor: "#047857",
        },
        {
          id: 1,
          img: image2,
          text: "Une situtation d'urgence peut arriver à tout un chacun",
          textColor: "#dc2626",
        },
        {
          id: 2,
          img: image3,
          text:
            "Apprendre à garder son calme, analyser la situation, appeler les secours correctement, poser les premiers gestes de secours, éventuellement réanimer une victime,...",
          textColor: "#ffff",
        },
      ],
    };
  },

  methods: {
    textAnimation(splide, destIndex) {
      this.slideNumber = destIndex;
    },
    track() {
      this.$gtag.screenview({ app_name: "Home", screen_name: "Home" });
    },
  },
  mounted() {
  },
};
</script>

<style>
/* @keyframes slideAnimation {
  0% {
    opacity: 0%;
    margin-left: 70%;
  }
  100% {
    opacity: 100%;
    margin-left: 10%;
  }
} */
@keyframes carouselImage {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
.slide-enter-active {
  animation-name: carouselImage;
  animation-timing-function: linear;
  animation-duration: 8.5s;
}
.slide-leave-active {
  animation-name: carouselImage;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-direction: reverse;
}
</style>
