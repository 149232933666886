<template>
    <div
      class="fixed bottom-0 flex items-center w-full h-10 text-white sm:h-16"
      style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%);"
    >
      <ul class="flex items-center justify-between w-full mx-5">
        <li class="w-1/3 text-left">
          <router-link to="/about">A propos</router-link>
        </li>
        <div class="w-1/3 text-center">
          <p>info@secourisme.life</p>
        </div>
        <li class="w-1/3 text-right">
          <router-link to="/contacts">Contacts</router-link>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style>
</style>
