<template>
  <div
    class="flex items-center justify-center w-full h-screen"
    style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%);"
  >
    <div>
      <span
        ><a
          href="mailto:nicolas@benard.be"
          target="_blank"
          class="flex text-6xl text-white bold pb-72 hover:underline"
          >Email: nicolas@benard.be</a
        ></span
      >
    </div>
  </div>
</template>

<script>
import store from "@/assets/formationsStore";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
export default {
  name: "Contact",
  data: () => {
    return {
      formations: store.state.formations,
      nameIsEmpty: false,
      firstNameIsEmpty: false,
      emailIsEmpty: false,
      titleIsEmpty: false,
      questionIsEmpty: false,
      readyToSend: false,
      formSended: false,
      formError: false,

      form: {
        name: null,
        firstName: null,
        company: null,
        email: null,
        formation: null,
        title: null,
        question: null,
      },
    };
  },
  methods: {
    sendEmail(e) {
      const self = this;
      if (self.readyToSend) {
        emailjs
          .sendForm(
            "service_uzpmr6p",
            "template_3dzs2gz",
            e.target,
            "user_JrnDCE1HctbmEM6HfkZv3"
          )
          .then(
            function() {
              self.formSended = true;
              self.formError = false;
            },
            function() {
              self.formError = true;
              self.formSended = false;
            }
          );
      }
    },

    formCheck() {
      if (!this.form.name) {
        this.nameIsEmpty = true;
      } else {
        this.nameIsEmpty = false;
      }
      if (!this.form.firstName) {
        this.firstNameIsEmpty = true;
      } else {
        this.firstNameIsEmpty = false;
      }
      if (!this.form.email) {
        this.emailIsEmpty = true;
      } else {
        this.emailIsEmpty = false;
      }
      if (!this.form.title) {
        this.titleIsEmpty = true;
      } else {
        this.titleIsEmpty = false;
      }
      if (!this.form.question) {
        this.questionIsEmpty = true;
      } else {
        this.questionIsEmpty = false;
      }
      if (
        this.form.name &&
        this.form.firstName &&
        this.form.email &&
        this.form.title &&
        this.form.question
      ) {
        this.readyToSend = true;
      }
    },
    track() {
      this.$gtag.screenview({ app_name: "Contact", screen_name: "contact" });
    },
  },
  mounted() {
    init("user_JrnDCE1HctbmEM6HfkZv3");
  },
};
</script>

<style></style>
