<template>
  <div
    class="h-full min-h-screen pb-28"
    style="background: linear-gradient(90deg, rgba(65,179,229,1) 0%, rgba(5,132,206,1) 51%, rgba(49,86,163,1) 100%);"
  >
    <div
      class="items-start justify-center w-full pt-20 text-left text-white lg:flex lg:px-24"
    >
      <div
        class="flex items-center justify-center w-full h-full lg:items-start lg:w-1/3 lg:mr-5"
      >
        <img src="../assets/images/black-logo.svg" alt="" class="w-56 h-56" />
      </div>
      <p class="p-10 sm:text-2xl sm:font-bold sm:p-0 md:my-5 md:mx-10">
        Une situation d'urgence peut arriver à tout un chacun. Imaginez le
        chqoue psychologique pour la personne témoin d'un accident et qui ne
        sait pas comment réagir ou commet un geste imprudent qui aggrave les
        blessures. Pire encore si la victime est un membre de la famille,...
        <br />
        <br />
        Nous vous proposons des
        <span class="text-green-400 underline"
          >scénarios ludiques et adaptés à vos besoins</span
        >
        pour vous enseigner les bonnes pratiques.
        <br />
        <br />
        Tous nos formateurs sont
        <span class="text-green-400 underline"
          >issus du domaine de l'aide médicale urgente</span
        >, ils suivent régulièrement des mises à niveaux tant dans les secours
        en extra-hospitalier que dans la pédagogie, et sont réévalués au minimum
        une fois par an.
      </p>
    </div>
    <div class="flex items-end justify-end w-full pt-10">
      <img
        src="../assets/images/ambu.jpg"
        alt=""
        class="w-3/5 mr-8 rounded-lg"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'about',
  methods: {
    track() {
      this.$gtag.screenview({ app_name: "about", screen_name: "A propos" });
    },
  },
  mounted() {
  },
};
</script>
