import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import mainPage from "./view/home.vue";
import formations from "./view/formations.vue";
import about from "./view/about.vue";
import contacts from "./view/contact.vue";
import singleFormation from "./view/single-formation.vue";
// import VueCarousel from "vue-carousel";
import VueSplide from "@splidejs/vue-splide";
import "./assets/tailwind.css";
import VueGtag from "vue-gtag";

// Vue.use(VueCarousel);
Vue.use(VueSplide);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: mainPage,
      name: "mainPage",
    },
    {
      path: "/formations",
      component: formations,
      name: "formations",
    },
    {
      path: "/contacts",
      component: contacts,
      name: "contacts",
    },
    {
      path: "/formations/:id",
      component: singleFormation,
      name: "single-formation",
    },
    {
      path: "/about",
      component: about,
      name: "about",
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-209516364-1",
      params: { pageTrackerScreenviewEnabled: true, pageTrackerEnabled: true },
    },
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
